import { render, staticRenderFns } from "./WidgetSettingsScenariosItem.vue?vue&type=template&id=7d306d54&"
import script from "./WidgetSettingsScenariosItem.vue?vue&type=script&lang=js&"
export * from "./WidgetSettingsScenariosItem.vue?vue&type=script&lang=js&"
import style0 from "./WidgetSettingsScenariosItem.vue?vue&type=style&index=0&id=7d306d54&prod&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "general/lang/WidgetSettingsScenariosItem.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Freleases%2Ffront%2Ffront_131%2Fsrc%2Fbc%2Fcomponents%2FWidgetSettings%2FWidgetSettingsScenariosItem.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports