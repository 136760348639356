import Vue from 'vue'

import langManager from 'general/utils/LangManager'
const localization = require('general/lang/SettingsPanelControls.json')
export const $t = langManager.languagePack(localization)

const controlNames = {
  input: 'v-control-input',
  uploadImage: 'v-control-upload-image',
  select: 'v-control-select',
  checkbox: 'v-control-checkbox',
  checkboxes: 'v-control-checkboxes',
  radiobuttons: 'v-control-radiobuttons',
  textarea: 'v-control-textarea',
  typography: 'v-control-typography',
  sizing: 'v-control-sizing',
  spacing: 'v-control-spacing',
  borders: 'v-control-borders',
  corners: 'v-control-corners',
  background: 'v-control-background',
  qcolor: 'v-control-q-color-picker',
  options: 'v-control-options',
  loop: 'v-control-loop',
  visibility: 'v-control-visibility',
  items: 'v-control-items',
  position: 'v-control-position',
  inputType: 'v-control-input-type',
  inputWithDescription: 'v-control-input-with-description',
  imageCompression: 'v-control-image-compression',
  multipleFiles: 'v-control-multiple-files',
  colorPicker: 'v-control-color-picker',
  inputUnits: 'v-control-input-units',
  expansion: 'v-control-expansion',
  text: 'v-control-text',
  container: 'v-control-empty',
  customClassSelect: 'v-custom-class-select',
}

// export const makeControlProps = (controlName, props) => ({
//   controlName: controlNames[controlName],
//   ...props
// });

export const makeControlProps = (controlName, props) => {
  props = typeof props === 'function' ? { _lambda: props } : props
  return {
    controlName: controlNames[controlName],
    ...props,
  }
}

export const fonts = [
  { label: 'Default', value: null },
  { label: 'Arial', value: 'Arial, Helvetica, sans-serif' },
  { label: 'Arial Black', value: '"Arial Black", Gadget, sans-serif' },
  { label: 'Comic Sans MS', value: '"Comic Sans MS", cursive, sans-serif' },
  { label: 'Impact', value: 'Impact, Charcoal, sans-serif' },
  { label: 'Lucida Sans Unicode', value: '"Lucida Sans Unicode", "Lucida Grande", sans-serif' },
  { label: 'Tahoma', value: 'Tahoma, Geneva, sans-serif' },
  { label: 'Trebuchet MS', value: '"Trebuchet MS", Helvetica, sans-serif' },
  { label: 'Verdana', value: 'Verdana, Geneva, sans-serif' },
  { label: 'Georgia', value: 'Georgia, serif' },
  { label: 'Palatino Linotype', value: '"Palatino Linotype", "Book Antiqua", Palatino, serif' },
  { label: 'Times New Roman', value: '"Times New Roman", Times, serif' },
  { label: 'Courier New', value: '"Courier New", Courier, monospace' },
  { label: 'Courier', value: 'Courier, "Courier New", monospace' },
  { label: 'Lucida Console', value: '"Lucida Console", Monaco, monospace' },
  { label: 'SF Pro', value: '"SF Pro Text"' },
  { label: 'SF Pro Light', value: '"SF Pro Text Ligth"' },
  { label: 'DM Sans', value: '"DM Sans", sans-serif' },
  { label: 'Font Awesome 5', value: '"Font Awesome 5 Pro", Helvetica, sans-serif' },
  { label: 'Font Awesome 5 Light', value: '"Font Awesome 5 Pro Light", Helvetica, sans-serif' },
 { label: 'Bocklin', value: '"Bocklin"' },
  { label: '321impact', value: '"321impact"' },
  { label: 'TrebuchetMS', value: '"TrebuchetMS"' },
]

export const visibilityProps = makeControlProps('visibility', {
  type: 'collapse',
  isHidden: {
    pathValue: 'properties.visibility.hidden',
    label: 'Hidden',
  },
  pathValue: 'properties.visibility',
  label: 'Visibility',
})

export const loopControlProps = makeControlProps('loop', {
  type: 'collapse',
  label: 'Loop',
  isEnabled: {
    label: 'Enabled',
    pathValue: 'properties.loop.isEnabled',
  },
  dataSource: {
    label: 'Data Source',
    pathValue: 'properties.loop.dataSource',
  },
  aliasName: {
    label: 'Loop Name',
    pathValue: 'properties.loop.aliasName',
  },
  isShowPreloader: {
    label: 'Show Preloader',
    pathValue: 'properties.loop.isShowPreloader',
  },
  isPagination: {
    label: 'Enable pagination',
    pathValue: 'properties.loop.pagination.enabled',
    subcontrols: {
      true: [
        makeControlProps('text', {
          label: $t('loop.infiniteScrollingHeightWarning'),
          class: 'bg-amber-2',
        }),

        makeControlProps('input', (componentData) => {
          // ** set default `items per page`, if it not set
          if (!parseInt(componentData.properties.loop.pagination.pageSize)) {
            Vue.set(componentData.properties.loop.pagination, 'pageSize', '10')
          }

          return {
            pathValue: 'properties.loop.pagination.pageSize',
            label: 'Items per page',
            type: 'number',
            subtype: 'int',
          }
        }),

        makeControlProps('select', {
          pathValue: 'properties.loop.pagination.showButtonUp',
          label: 'Show button to go up',
          options: [{ label: 'No', value: false }, { label: 'Yes', value: true }],
        }),

        makeControlProps('select', {
          pathValue: 'properties.loop.pagination.showInformer',
          label: 'Show page informer',
          options: [{ label: 'No', value: false }, { label: 'Yes', value: true }],
        }),
      ],
    },
  },
})

export const backgroundProps = makeControlProps('background', {
  label: 'Background',
  color: {
    label: 'Color',
    pathValue: 'background-color',
  },
  image: {
    label: 'Image',
    pathValue: 'background-image',
  },
  repeat: {
    label: 'Repeat',
    pathValue: 'background-repeat',
    options: [
      { label: 'no repeat', value: 'no-repeat' },
      { label: 'repeat', value: 'repeat' },
      { label: 'repeat-x', value: 'repeat-x' },
      { label: 'repeat-y', value: 'repeat-y' },
    ],
  },
  size: {
    label: 'Background Size',
    pathValue: 'background-size',
    options: [
      { label: 'auto', value: 'auto' },
      { label: 'contain', value: 'contain' },
      { label: 'cover', value: 'cover' },
    ],
  },
  positionX: {
    label: 'Position-x',
    measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
    pathValue: 'background-position-x',
  },
  positionY: {
    label: 'Position-y',
    measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
    pathValue: 'background-position-y',
  },
})

export const sizingProps = makeControlProps('sizing', {
  label: 'Sizing',
  width: {
    label: 'Width',
    measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
    pathValue: 'width',
  },
  maxWidth: {
    label: 'Max. Width',
    measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
    pathValue: 'max-width',
  },
  minWidth: {
    label: 'Min. Width',
    measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
    pathValue: 'min-width',
  },
  height: {
    label: 'Height',
    measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
    pathValue: 'height',
  },
  maxHeight: {
    label: 'Max. Height',
    measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
    pathValue: 'max-height',
  },
  minHeight: {
    label: 'Min. Height',
    measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
    pathValue: 'min-height',
  },
})

export const typographyProps = makeControlProps('typography', {
  label: 'Typography',
  font: {
    label: 'Font',
    pathValue: 'font-family',
    options: fonts,
  },
  size: {
    label: 'Size',
    measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
    pathValue: 'font-size',
  },
  lineHeight: {
    label: 'Line Height',
    measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
    pathValue: 'line-height',
  },
  letterSpacing: {
    label: 'Letter Spacing',
    measure: ['px', 'em', '%', 'rem', 'vw', 'vh'],
    pathValue: 'letter-spacing',
  },
  color: {
    label: 'Color',
    pathValue: 'color',
  },
  textTransform: {
    label: 'Text Transform',
    pathValue: 'text-transform',
    options: [
      { label: 'none', value: 'none' },
      { label: 'capitalize', value: 'capitalize' },
      { label: 'uppercase', value: 'uppercase' },
      { label: 'lowercase', value: 'lowercase' },
    ],
  },
  whiteSpace: {
    label: 'White Space',
    pathValue: 'white-space',
    options: [
      { label: 'normal', value: 'normal' },
      { label: 'nowrap', value: 'nowrap' },
      { label: 'pre', value: 'pre' },
      { label: 'pre-line', value: 'pre-line' },
      { label: 'pre-wrap', value: 'pre-wrap' },
    ],
  },
  style: {
    label: 'Text style',
    options: [
      {
        icon: 'fa fa-italic',
        value: 'italic',
        pathValue: 'font-style',
      },
      {
        icon: 'fa fa-bold',
        value: 'bold',
        pathValue: 'font-weight',
      },
      {
        icon: 'fa fa-underline',
        value: 'underline',
        pathValue: 'text-decoration',
      },
    ],
  },
  align: {
    label: 'Align',
    pathValue: 'text-align',
    options: [
      {
        icon: 'fa fa-align-left',
        value: 'left',
      },
      {
        icon: 'fa fa-align-center',
        value: 'center',
      },
      {
        icon: 'fa fa-align-right',
        value: 'right',
      },
      {
        icon: 'fa fa-align-justify',
        value: 'justify',
      },
    ],
  },
})
