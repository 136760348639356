<template>
	<q-header :class="{ minimized: headerMinimized, relativePosition: true }">
		<div class="row no-wrap full-width items-center mbst-header" @mouseover="headerHover" @mouseleave="headerMouseOut">
			<q-btn flat round size="xs" class="absolute-top-right" :icon="headerMinimizable ? 'expand_more' : 'expand_less'" @click="setHeaderMinimizable(!headerMinimizable)">
				<q-tooltip v-if="headerMinimizable">Show always</q-tooltip>
				<q-tooltip v-else>Hide automaticaly</q-tooltip>
			</q-btn>
			<template v-if="!headerMinimized">
			<div class="col-auto q-px-sm q-mr-lg" data-tour="menu-dashboard">
				<q-btn
					size="md" round flat
					@click.native="goto('/')"
				>
					<q-icon size="2em" name="img:/front/src/bc/assets/img/logo-sm-white.svg" />
					<q-tooltip
						max-width="200px"
						anchor="bottom middle"
						self="top middle"
					>
						<div class="text-subtitle1">All Apps</div>
						<div class="text-body2">Home page, where all apps of this account are listed and managed.</div>
					</q-tooltip>
				</q-btn>
			</div>

			<div class="col-auto" data-tour="menu-section-general">
				<section-menu :section="sectionsMenu.general" :active-item="activeItem"></section-menu>
			</div>
			<div class="q-separator q-separator--vertical q-mx-lg"></div>
			<div class="col-auto" data-tour="menu-section-integrations">
				<section-menu :section="sectionsMenu.api" :active-item="activeItem"></section-menu>
			</div>

			<div class="col-auto">
				<div v-if="$store.state.application.data.attributes" class="row no-wrap items-center" data-tour="constroctor-menu">
					<div class="q-separator q-separator--vertical q-mx-lg"></div>
					<div class="col-auto">
						<span class="mbst-header__app-name">
							{{ $store.state.application.data.attributes.Name | truncate(10) }}
						</span>
					</div>
					<div class="col-auto q-ml-md" data-tour="menu-section-appSetup">
						<section-menu :section="sectionsMenu.appSettings" :active-item="activeItem"></section-menu>
					</div>
					<div class="col-auto q-ml-md" data-tour="menu-section-appData">
						<section-menu :section="sectionsMenu.appData" :active-item="activeItem"></section-menu>
					</div>
				</div>
			</div>

			<q-space />

			<div class="col-auto">
				<div class="row no-wrap items-center q-px-sm q-mt-md" data-tour="menu-section-additional">

					<q-btn v-if="!$store.state.application.data.attributes" id="btnSyncLogintap" flat no-caps class="bg-warning" text-wrap label="LOGIN WITH" icon="fas fa-user-lock" @click="onShowLogintapWidget()" style="background-color:#4cae4c">
						<q-tooltip
								max-width="300px"
								anchor="bottom middle"
								self="top middle"
						>
							<div class="text-subtitle1">Authentication via messenger</div>
							<div class="text-body2">Connect your messenger ot choice for two factor authetication into Mobsted. Enter your e-mail and press messengers button in login form.</div>
						</q-tooltip>
						<div style="margin-left: 12px;font-size: 19px;">
							<i class="fab fa-facebook-messenger"></i>
							<i class="fab fa-telegram"></i>
							<i class="fab fa-viber"></i>
							<i class="fab fa-vk"></i>
						</div>
					</q-btn>

					<div class="mbst-header__item is-middle q-ml-sm" :class="{'is-active': 'partners' == activeItem}">
						<q-btn
							type="a"
							round flat
							href="/partners/index"
						>
							<q-icon size="1.25em" name="fas fa-handshake" />
							<q-tooltip
								max-width="200px"
								anchor="bottom middle"
								self="top middle"
							>
								<div class="text-subtitle1">Partners</div>
								<div class="text-body2">Partnership reseller program</div>
							</q-tooltip>
						</q-btn>
					</div>

					<q-btn round flat>
						<q-icon size="1.25em" name="fas fa-question-circle" />
						<q-tooltip
							max-width="200px"
							anchor="bottom middle"
							self="top middle"
						>
							<div class="text-subtitle1">Help</div>
						</q-tooltip>
						<q-menu auto-close anchor="top middle" self="bottom right">
							<q-list>
								<q-item-label header>Live Guides</q-item-label>
								<q-item
									clickable v-ripple
									@click="onStartQuickAppCreationGuide"
								>
									<q-item-section>Quick App Creation</q-item-section>
								</q-item>
								<q-item
									clickable v-ripple
									@click="onStartIntroGuide"
								>
									<q-item-section>Intro</q-item-section>
								</q-item>
								<q-item
									clickable v-ripple
									@click="onStartConstructorGuide"
								>
									<q-item-section>Constructor</q-item-section>
								</q-item>
								<q-item
									clickable v-ripple
									@click="onStartLayoutLiveGuide"
								>
									<q-item-section>Layout & Live Edit Mode</q-item-section>
								</q-item>
								<q-item
									clickable v-ripple
									@click="onStartSecurityGuide"
								>
									<q-item-section>Security</q-item-section>
								</q-item>
								<q-item
									clickable v-ripple
									@click="onStartStatusesGuide"
								>
									<q-item-section>Statuses</q-item-section>
								</q-item>

								<q-item
									clickable v-ripple
									@click="onStartWidgetsGuide"
								>
									<q-item-section>Widgets</q-item-section>
								</q-item>
								<q-item
									clickable v-ripple
									@click="onStartInviteNotifyGuide"
								>
									<q-item-section>Invitation and Notifications</q-item-section>
								</q-item>
								<q-separator spaced />
								<q-item
									clickable v-ripple
									@click.native="goto('https://docs.mobsted.com/', '_blank')"
								>
									<q-item-section>Knowledge Base</q-item-section>
								</q-item>
								<q-item
									clickable v-ripple
									@click.native="goto('https://mobsted.com/data_structure', '_blank')"
								>
									<q-item-section>Data Org Chart</q-item-section>
								</q-item>
								<q-item
									clickable v-ripple
									@click.native="goto(subjectAskUs, '_blank')"
								>
									<q-item-section>Ask us at support@mobsted.com</q-item-section>
								</q-item>
							</q-list>
						</q-menu>
					</q-btn>
					<div class="mbst-header__item is-middle q-ml-sm" :class="{'is-active': 'profile' == activeItem}">
						<q-btn
							type="a"
							round flat
							href="/myprofile/index"
						>
							<q-icon size="1.25em" name="fas fa-user-circle" />
							<q-tooltip
								max-width="200px"
								anchor="bottom middle"
								self="top middle"
							>
								<div class="text-subtitle1">My Profile</div>
								<div class="text-body2">User's personal login info</div>
							</q-tooltip>
						</q-btn>
					</div>
					<q-btn
						class="q-ml-sm"
						round flat
						@click.native="goto('/users/logout')"
					>
						<q-icon size="1.25em" name="fas fa-sign-out-alt" />
						<q-tooltip
							max-width="200px"
							anchor="bottom middle"
							self="top middle"
						>
							<div class="text-subtitle1">Logout</div>
							<div class="text-body2">Exit Mobsted backend</div>
						</q-tooltip>
					</q-btn>
				</div>
			</div>
		</template>
		</div>
		<v-teleport-location name="subHeader" tag="div" style="border-bottom: 1px solid silver;" />
	</q-header>
</template>

<script>
import { mapState } from 'vuex';
import Backbone from 'backbone';
const ModalHeroView = require('views/modal-hero/ModalHeroView');
const OperationsView = require('views/operations/OperationsView');
const OperationsCollection = require('collections/OperationsCollection');

import SectionMenu from './SectionMenu';

import MakeEventBus from 'general/utils/MakeEventBus';
const EventBus = MakeEventBus('root');

import { vTeleportLocation } from '@desislavsd/vue-teleport'
	export default {
	  name: 'mbst-layout-header',
		components: { SectionMenu, vTeleportLocation },
		data: function () {
	    return {
				headerMinimizable: false,
				headerMinimized: false
      }
    },
		computed: {
      ...mapState({
				application: state => state.application
      }),
			activeItem: vm => vm.$route.name,
			subjectAskUs: () => `mailto:support@mobsted.com?subject=${window.location.origin}`,
      sectionsMenu: vm => ({
        general: {
          title: 'General',
          items: [
            {
              to: '/users/index/0',
              name: 'users',
              icon: 'fas fa-users',
              title: 'System Users',
              description: 'Users who have rights to access this backend to view or make changes.'
            },
            {
              to: '/accountsettings/index',
              name: 'account-settings',
              icon: 'fas fa-cogs',
              title: 'Account Settings',
              description: 'General settings of messaging and payments gateways, security keys, statistics and billing.'
            },
            {
              to: '/senderpool/index/0',
              name: 'outbox',
              icon: 'fas fa-paper-plane',
              title: 'Outbox',
              description: 'Queue, statuses, timing for all types of outgoing messages from all apps of this account.'
            },
          ]
        },
        api: {
          title: 'Integrations',
          items: [
            {
              to: '/apiproviders/index',
              name: 'api-providers',
              icon: 'fas fa-key',
              title: 'API Providers',
              description: 'All system\'s internal and custom added external connectors with endpoints and keys.'
            },
            {
              action: () => vm.showApiOperations(),
              name: 'api-operations',
              icon: 'fas fa-project-diagram',
              title: 'API Operations',
              description: 'Operations or actions with API Providers, which are executed from app or triggers.'
            },
            {
              to: '/logs/index',
              name: 'api-logs',
              icon: 'fas fa-history',
              title: 'History of API calls',
              description: 'View history of all API requests made by your account to make sure your apps work properly. Opens in a new browser tab for convenience'
            },

            {
              to: '/publicstore/index',
              name: 'aps-publicate',
              icon: 'fas fa-store',
              title: 'App Store',
              description: 'List the app on a popular store'
            },

          ]
        },
        appSettings: {
          title: 'App Setup',
          items: [
            {
              to: `/applications/edit/${vm.application.data.id}/2`,
              name: 'app-settings',
              icon: 'fas fa-sliders-h',
              title: 'App Settings',
              description: 'Basic PWA app\'s setup of a name, icon, splash screen, browser frames.'
            },
            {
              to: `/applications/edit/${vm.application.data.id}/3`,
              name: 'builder',
              icon: 'fas fa-puzzle-piece',
              title: 'Constructor',
              description: 'The dark side.'
            },
            {
              to: `/applications/edit/${vm.application.data.id}/4`,
              name: 'security',
              icon: 'fas fa-shield-check',
              title: 'Security',
              description: 'Methods to login and authorise app\'s mobile users.'
            },
            {
              to: `/widgetsettings/edit/${vm.application.data.id}`,
              name: 'widgets',
              icon: 'fas fa-layer-plus',
              title: 'Widgets',
              description: 'App saving and push/messengers capturing, for in app and external websites. '
            },
            {
              to: `/bot/edit/${vm.application.data.id}`,
              name: 'chat-bots',
              icon: 'fas fa-comments',
              title: 'Chat Bots',
              description: 'Bot connectors of the app to Telegram, Facebook Messenger, Viber, VK.'
            },
            {
              to: `/applications/edit/${vm.application.data.id}/7`,
              name: 'invitation-notifications',
              icon: 'fas fa-user-check',
              title: 'Invitation & Notifications',
              description: 'Initial user invites and further notification preferences between push, sms and emails.'
            },
          ]
        },
        appData: {
          title: 'App Data',
          items: [
            {
              to: `/objects/index/${vm.application.data.id}`,
              name: 'objects',
              icon: 'fas fa-poll-people',
              title: 'Objects',
              description: 'Description data of a central entity of an app - most often users or customers; sometimes equipment, buildings, etc.'
            },
            {
              to: `/backend/index/${vm.application.data.id}`,
              name: 'events',
              icon: 'fas fa-database',
              title: 'Events',
              description: 'Data created by Object\'s actions within the app - i.e. login, form fill up, button press, file upload, etc.'
            },
            {
              to: `/lists/index/${vm.application.data.id}`,
              name: 'lists',
              icon: 'fal fa-table',
              title: 'Lists of Data Tables',
              description: 'One or many custom tables of data in each list to be used in app, flat or JSON'
            },
            {
              to: `/statuses/edit/${vm.application.data.id}`,
              name: 'statuses',
              icon: 'fas fa-tags',
              title: 'Statuses',
              description: 'Custom states assigned to Events - i.e. open, closed, new, expired, etc.'
            },
          ]
        }
      }),
		},
		created () {
			const interfaceStatus = this.$q.localStorage.getItem('interface') || {}
			this.headerMinimizable = interfaceStatus.headerMinimizable || false
			if (this.headerMinimizable) this.headerMinimized = true
		},
		methods: {
			onShowLogintapWidget() {
				const botlinksEndpoint = {
					url: "/logintap/links?t=" + new Date().getTime(),
					method: "get",
					data: {}
				}

				window.MBST.qrw.init({botlinksEndpoint});
				window.MBST.qrw.show();
			},
			goto(path, target) {
				this.$root.onGoto(path, target);
			},

      /**
			 * I'm sorry, the time crutch
       */
      showApiOperations() {
        const Model = Backbone.Model.extend();
        const modalHeroView = new ModalHeroView({
          model: new Model({
            header: {
              title: 'Operations',
            },
            body: '',
            footer: {
              buttons: [
                {
                  text: 'Close',
									className: 'btn-default',
									attr: 'data-js-toggle="closeModalHero"'
								},
              ],
            },
          }),
        });
        modalHeroView.render();

        const operationsView = new OperationsView({
          collection: new OperationsCollection(),
        });

        operationsView.collection.fetch({
          reset: true,
          beforeSend: function () {
            modalHeroView.$('.b-modal-hero__body').append('<div class="b-table" style="text-align: center">' +
              '<div class="b-table__cell">' +
              '<div class="b-spinner b-spinner--anim b-spinner--primary is-lg"></div>' +
              '<div style="color: #4a535e;font-weight: 700;text-transform: uppercase;margin-top: 15px;">Loading Data</div>' +
              '</div>' +
              '</div>');
          },
          success: function (collection, response, options) {
            // TODO: добавить обработку естессна (если нет данных соответсвенно сообщить об этом)
            // console.log(collection);
            modalHeroView.$('.b-modal-hero__body').attr('id', 'operations').html(operationsView.$el);
            operationsView.$el.css('padding-bottom', operationsView.$('>.b-global-box__header').outerHeight());
          },
          error: function (collection, response, options) {
            // console.log(collection);
          },
          complete: function (collection, response, options) {
            // console.log(collection);
          },
        });
			},

			onStartQuickAppCreationGuide() {
        this.resetGuide('first_guide');
        this.$q.localStorage.remove('startGuide');
        this.$q.localStorage.remove('interface');
        window.location = '/';
			},

      onStartIntroGuide() {
        this.resetGuide('intro_guide');
			  this.$q.localStorage.set('startGuide', 'intro_guide');
        window.location = '/';
			},

			onStartConstructorGuide() {
        this.resetGuide('constructor_guide');
        this.$q.localStorage.set('startGuide', 'constructor_guide');
        EventBus.$emit('root:startConstructorGuide');
			},
      onStartLayoutLiveGuide() {
        this.resetGuide('guide_layout_live');
        this.$q.localStorage.set('startGuide', 'guide_layout_live');
        EventBus.$emit('root:startLayoutLiveGuide');
      },
      onStartSecurityGuide() {
        this.resetGuide('guide_security');
        this.$q.localStorage.set('startGuide', 'guide_security');
        EventBus.$emit('root:startSecurityGuide');
      },
      onStartStatusesGuide() {
        this.resetGuide('guide_statuses');
        this.$q.localStorage.set('startGuide', 'guide_statuses');
        EventBus.$emit('root:startStatusesGuide');
      },
      onStartWidgetsGuide() {
        this.resetGuide('guide_widgets');
        this.$q.localStorage.set('startGuide', 'guide_widgets');
        EventBus.$emit('root:startWidgetsGuide');
      },
			onStartInviteNotifyGuide() {
        this.resetGuide('guide_invintation_notifications');
        this.$q.localStorage.set('startGuide', 'guide_invintation_notifications');
        EventBus.$emit('root:startInviteNotifyGuide');
      },

			resetGuide(name) {
        const toursData = this.$q.localStorage.getItem('tour') || {};
        for (let page in toursData) {
          for (let tour of toursData[page]) {
            if (tour.name.indexOf(name) != -1) {
              tour.isVisit = false;
            }
          }
        }
        this.$q.localStorage.set('tour', toursData);
			},

			setHeaderMinimizable (val) {
				this.headerMinimizable = val
				this.saveInterfaceStatus({ headerMinimizable: val })
			},

			headerHover (e) {
				if (this.headerLeaveTimer) {
					clearTimeout(this.headerLeaveTimer)
				}
				if (this.headerMinimized) {
					this.headerMaximizeTimer = setTimeout(() => (this.headerMinimized = false), 400)
				}
			},

			headerMouseOut (e) {
				if (!this.headerMinimizable) return
				if (this.headerMaximizeTimer) {
					clearTimeout(this.headerMaximizeTimer)
				}
				this.headerLeaveTimer = setTimeout(() => (this.headerMinimized = true), 400)
			},

			saveInterfaceStatus (params) {
				this.$store.commit('saveInterfaceStatus', params)
      }
		}
	}
</script>

<style lang="scss">
	.b-app-body .q-layout {
		padding: 0 !important;
		width: 100% !important;
		height: 100% !important;
		min-height: inherit !important;
	}
	header {
		.mbst-header {
			transition: height .4s;
		}
		&.minimized {
			min-height: 6px;
			.mbst-header {
				height: 6px;
			}
		}
	}
	.mbst-header {
		// min-width: 1200px;
		height: 68px;
		// padding: 5px 0;
		background-color: #428BCA;
		display: flex;
		align-items: center;
		box-shadow: 0 1px 6px rgba(0, 0, 0, 0.3);
		overflow: hidden;

		&:hover {
			overflow-x: overlay;
		}

		&::-webkit-scrollbar {
		  width: 10px;
			height: 10px;
		}
		// &::-webkit-scrollbar-track {
		//   border-radius: 10px;
		// }
		&::-webkit-scrollbar-thumb {
		  background: #ffffff88;
		  border-radius: 5px;
		}

		&__app-name {
			font-size: .85em;
			text-transform: uppercase;
		}

		.q-separator {
			background: white;
			opacity: .15;
			align-self: center;
			min-height: 1px;
			height: 38px;
		}
		.q-icon {
			opacity: .75;
		}
	}
</style>
