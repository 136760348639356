export default {
  isLoadingPage: false,
  rawData: {},
  isOpenedDialog: false,
  $dComponentName: null,
  $dList: null,
  $dTable: null,
  $dRow: null,
  columnTypeOptions: [
    { label: 'Varchar (250)', value: 'varchar(250)'},
    { label: 'Text', value: 'text'},
    { label: 'Integer', value: 'integer'},
    { label: 'Float', value: 'float'},
    { label: 'Boolean', value: 'boolean'},
    { label: 'JSONB', value: 'jsonb'},
    { label: 'Date (timestamp without time zone)', value: 'timestamp without time zone'},
  ],
}
